<template>
  <div>
    <MusicList />
  </div>
</template>

<script>
import MusicList from '@/components/MusicList'
  export default {
    components: {
      MusicList
    }
  }
</script>

<style scoped>

</style>