<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10">
            <div class="text-center mb-3">
              <h6
                v-if="!successMessage"
                class="text-blueGray-500 text-xl font-bold">
                Reset your password 
              </h6>
              <h6
                v-if="successMessage"
                class="text-green-600 text-xl font-bold">
                Success
              </h6>
            </div>

            <div
              v-if="successMessage" 
              class="text-blueGray-600 text-center mb-3">
              {{ this.message }}
            </div>

            <form
              v-if="!successMessage"
              @submit.prevent="submitResetPassword">
              <p class="mb-4">
                Enter your e-mail to reset your password. An email will be sent to you with instructions about how to complete the process.
              </p>
              <!-- <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  System
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="system"
                />
              </div> -->
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  E-mail
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="email"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit">
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ToastError } from '../../utils/Swal'

export default {
  data() {
    return {
      message: 'Please check your email inbox for a link to complete the reset.',
      successMessage: false,
      // system: '',
      email: ''
    }
  },
  methods: {
    async submitResetPassword() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/forgot-password`, {
          email: this.email,
        }, { withCredentials: true })

        if (response.message) {
          this.successMessage = true
          this.message = response.message
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }    
    }
  },
}
</script>
