<template>
  <div>
    <DeleteModal
      @close-delete-modal="showDeleteModal = !showDeleteModal"
      @deleteItem="deleteMusic"
      @cancelDeleteProcess="cancelDeleteProcess"
      v-if="showDeleteModal"
      itemType="Music"
      />
    <MusicModal
      @close-modal="showModal = !showModal"
      @save="save"
      @hideModal="showModal = !showModal"
      v-if="showModal"
      :musicObject="musicObject"
    />
    <div class="flex justify-between items-center py-4">
      <h2
        class="text-blueGray-700 text-lg font-semibold">
        Músika
      </h2>
      <button
        @click="openModal"
        class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">Add Músika</button>
    </div>
    <div class="app-block mb-22 shadow-lg">
      <div class="">
          <ul>
            <li 
              v-for="(music, idx) in musicList"
              :key="idx"
              class="flex justify-between pt-4 pb-4">
              <div class="">
                <h2
                  v-html="music.title"
                  class="text-lg font-semibold">
                </h2>
                <p
                  v-if="music.categoryId"
                  class="font-semibold text-xs">Category:
                  <span class="text-xs font-normal">
                    {{ categoryName(music.categoryId) }}
                  </span>
                </p>
              </div>
              <div class="flex justify-between">
                <span>
                  <i
                    @click="edit(music._id)"
                    class="fas fa-pencil-alt cursor-pointer"></i>
                </span>
                <span>
                  <i
                    @click="showDeleteMusicModal(music._id)"
                    class="fas fa-trash-alt ml-4 cursor-pointer"></i>
                </span>
              </div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MusicModal from '@/components/modals/MusicModal';
import DeleteModal from '@/components/modals/DeleteModal';
import { ToastSuccess, ToastError } from '../utils/Swal'
import FirebaseStorage from '../utils/fileUpload'

import axios from 'axios'

export default {
  components: {
    MusicModal,
    DeleteModal
  },

  data() {
    return {
      musicObject: {},
      musicList: [],
      showModal: false,
      showDeleteModal: false,
      idOfItemToDelete: null,

      musicObjectToSave: {},
      firebaseStorage: new FirebaseStorage()
    }
  },

  methods: {

    openModal() {
      this.musicObject = {}
      this.showModal = !this.showModal
    },

    categoryName(id) {
      const category = this.$store.getters.getCategories.find(category => category._id === id)
      if (category) {
        return category.name
      } else {
        return ''
      }
    },

    async save(object) {
      this.musicObjectToSave = object

      if (object.file !== null) {
        if (this.musicObjectToSave.queryMode === 'new') {
          await this.saveFileInStorage()
        } else if (this.musicObjectToSave.queryMode === 'edit') {
          await this.editFileInStorage() 
        }
        this.databaseHandler()
      } else {
        this.databaseHandler()
      }
    },

    async databaseHandler() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/${this.musicObjectToSave.queryMode}-music`, 
          this.musicObjectToSave.form,
          { withCredentials: true })
      
        if (response.saved) {
          ToastSuccess.fire({
            title: `<p style="color: #fff";>${response.message}</p>`
          })

          if (this.musicObjectToSave.queryMode === 'new') {
            this.$store.dispatch('setNewMusic', response.doc)
          } else if (this.musicObjectToSave.queryMode === 'edit') {
            this.$store.dispatch('editMusic', response.doc)
            this.refreshList()
          }
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    async saveFileInStorage() {
      let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
      this.musicObjectToSave.form.filePath = `${this.musicObjectToSave.path}/${uniqueId}/`

      const payload = await this.firebaseStorage.save({
        file: this.musicObjectToSave.file,
        path: this.musicObjectToSave.form.filePath
      })

      this.musicObjectToSave.form.filename = payload.file.name
      this.musicObjectToSave.form.downloadURL = payload.file.downloadURL
    },

    async editFileInStorage() {
      const payload = await this.firebaseStorage.edit({
        file: this.musicObjectToSave.file,
        previousFilename: this.musicObjectToSave.form.filename,
        path: this.musicObjectToSave.form.filePath
      })
          
      this.musicObjectToSave.form.filename = payload.file.name
      this.musicObjectToSave.form.downloadURL = payload.file.downloadURL
    },
    
    edit(id) {
      this.musicObject = this.musicList.find(music => music._id === id)
      this.showModal = !this.showModal
    },

    cancelDeleteProcess() {
      this.idOfItemToDelete = null
      this.showDeleteModal = false
    },

    async deleteMusic() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/delete-music`,
        { id: this.idOfItemToDelete },
        { withCredentials: true })

        if (response.deleted) {
          this.firebaseStorage.delete({
            path: response.doc.filePath,
            previousFilename: response.doc.filename
          })

          this.$store.dispatch('deleteMusic', this.idOfItemToDelete)
          this.idOfItemToDelete = null
          this.refreshList()
          this.showDeleteModal = false
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    showDeleteMusicModal(id) {
      this.idOfItemToDelete = id
      this.showDeleteModal = true
    },
    
    refreshList() {
      this.musicList = this.$store.state.music
    }
  },

  async mounted() {
    try {
      const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/get-music`, {},
      { withCredentials: true })
      if (response.doc) {
        this.$store.dispatch('loadMusic', response.doc)
        this.musicList = (response.doc).reverse()
      }
    } catch (error) {
      if (error.response) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      } else {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

  ul li {
    border-top: 1px solid grey;
  }

  ul li:first-child {
    border-top: 0px solid grey;
  }
</style>