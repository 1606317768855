<template>
  <div>
    <PrayersList />
  </div>
</template>

<script>
import PrayersList from '@/components/PrayersList'
  export default {
    components: {
      PrayersList
    }
  }
</script>

<style scoped>

</style>