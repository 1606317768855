<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 rounded-t mb-0 px-6 py-6">
          
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-xl font-bold">
                Sign in 
              </h6>
            </div>

            <form @submit.prevent="loginAdmin">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password">
                  Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="password"
                />
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit">
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="/forgot-password" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ToastError } from '../../utils/Swal'

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    async loginAdmin() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/login`, {
          email: this.email,
          password: this.password
        }, { withCredentials: true })

        if (response.user.role !== 'admin') {
          throw {
            message: 'Access denied.',
            role: response.user.role
          }
        }

        if (Object.keys(response.user).length === 0) throw 'Something went wrong. Please try again.'

        this.$store.dispatch('setAuth', true)
        this.$store.dispatch('setUser', response.user)
        this.$router.replace('/admin/calendar')
        
      } catch (error) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      }    
    }
  }
}
</script>
