<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10">

            <div class="text-center mb-8">
              <h6
                v-if="!message"
                class="text-blueGray-500 text-xl font-bold">
                Create a new password 
              </h6> 
            </div>

            <div
              v-if="message" 
              class="text-blueGray-600 text-center mb-3">
              <h6
                v-if="message"
                class="text-blueGray-500 text-xl font-bold mb-4">
                Success
              </h6>

              <p class="mb-6"> {{ this.message }} </p>
              
              <router-link
                to="/login"
                class="rounded bg-blue-500 py-2 px-4 w-1/2 mx-auto font-bold block text-white">
                Log In
              </router-link>
            </div>

            <form
              v-if="!message" 
              @submit.prevent="resetPassword">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="newPassword"
                />
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Confirm new password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="confirmNewPassword"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit">
                  Save Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ToastError } from '../../utils/Swal'

export default {
  data() {
    return {
      message: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        ToastError.fire({
          title: `<p style="color: #fff";>Password does not match</p>`
        })
      } else {
        try {
          const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/reset-password`, {
            id: this.$route.query.id,
            email: this.$route.query.email,
            newPassword: this.newPassword
          }, { withCredentials: true })

          if (response.success) {
            this.message = response.message
          }
          
        } catch (error) {
          console.log(error.response.data.message)
        }  
      }
    }
  },
}
</script>
