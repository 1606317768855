import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import VueFlatPickr from 'vue-flatpickr-component';

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'flatpickr/dist/flatpickr.css';
import "@/assets/styles/tailwind.css";

createApp(App)
  .use(store)
  .use(router)
  .use(VueFlatPickr, {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    inline: true
  })
  .mount('#app')
