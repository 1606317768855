<template>
  <div>
    Dashboard
  </div>
</template>

<script>
  export default {
    
  }
</script>