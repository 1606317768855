import { createWebHistory, createRouter } from "vue-router";
import store from '../store/index'
import axios from 'axios'

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// layouts
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout
import Dashboard from "@/views/admin/Dashboard.vue";
import Calendar from "@/views/admin/Calendar.vue";
import Categories from "@/views/admin/Categories.vue";
import Events from "@/views/admin/Events.vue";
import Prayers from "@/views/admin/Prayers.vue";
import Katisashi from "@/views/admin/Katisashi.vue";
import Music from "@/views/admin/Music.vue";
import Settings from "@/views/admin/Settings.vue";
import Users from "@/views/admin/Users.vue";

// views for Auth layout
import AdminLogin from "@/views/admin/Login.vue";
import Signup from "@/views/admin/Signup.vue";
import AdminForgotPassword from "@/views/admin/ForgotPassword.vue";
import AdminPasswordReset from "@/views/admin/PasswordReset.vue";

// routes
const routes = [
  {
    path: "/admin",
    redirect: "/dashboard",
    component: Admin,
    beforeEnter: [checkAuthState],
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/calendar",
        component: Calendar,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/categories",
        component: Categories,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/prayers",
        component: Prayers,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/katisashi",
        component: Katisashi,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/music",
        component: Music,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/events",
        component: Events,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/settings",
        component: Settings,
        beforeEnter: [checkAuthState]
      },
      {
        path: "/admin/users",
        component: Users,
        beforeEnter: [checkAuthState]
      }
    ],
  },
  {
    path: "/admin",
    redirect: "/login",
    component: Auth,
    children: [
      {
        path: "/login",
        component: AdminLogin,
        beforeEnter: [checkAuthStatusOnAuthViews]
      },
      {
        path: "/signup",
        component: Signup,
        beforeEnter: [checkAuthStatusOnAuthViews]
      },
      {
        path: "/reset-password",
        component: AdminPasswordReset,
        beforeEnter: [checkAuthStatusOnAuthViews]
      },
      {
        path: "/forgot-password",
        component: AdminForgotPassword,
        beforeEnter: [checkAuthStatusOnAuthViews]
      }
    ],
  },
  { 
    path: "/:pathMatch(.*)*", 
    redirect: "/login" 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

async function checkAuthState() {
  try {
    const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/validate-cookie`, {}, {
      withCredentials: true
    })

    if (response.auth) return response.auth

  } catch (error) {
    if (error.response.status === 400 || error.response.status === 401 || error.response.status === 500) {
      store.state.authenticated = false
      window.localStorage.clear()
      return '/login'
    }
  }
}

function checkAuthStatusOnAuthViews() {
  if (!store.getters.isAuth) return true
  else return '/admin/dashboard'
}

export default router
