<template>
  <div>
    <KatisashiList />
  </div>
</template>

<script>
import KatisashiList from '@/components/KatisashiList'
  export default {
    components: {
      KatisashiList
    }
  }
</script>

<style scoped>

</style>