import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: "AIzaSyCDQWKBdLpnk0Ddjcqr9m2UE6kIt9A7kV0",
  authDomain: "religious-3928d.firebaseapp.com",
  projectId: "religious-3928d",
  storageBucket: "religious-3928d.appspot.com",
  messagingSenderId: "297373243833",
  appId: "1:297373243833:web:c8193ac140320cdf383e68"
};
export const app = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
// export const storage = getStorage(firebaseApp);
// export const analytics = getAnalytics(app);
