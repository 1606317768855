<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <users-table 
        :tableTitle="tableTitle"  
        :usersArray="getUsers" />
    </div>
  </div>
</template>
<script>
import UsersTable from "@/components/UsersTable.vue"
const axios = require('axios').default
import { mapGetters } from 'vuex'

export default {
  components: {
    UsersTable,
  },
  data() {
    return {
      tableTitle: 'Users'
    }
  },

  computed: {
    ...mapGetters([
      'getUsers'
    ])
  },

   async mounted() {
    if (this.getUsers.length === 0) {
      this.$store.dispatch('loading', true)
      try {
        const { data : response } = await axios.post(`https://qws-cms-api.onrender.com/admin/users`, {
          system: this.$store.getters.getSystem
        }, { withCredentials: true })
        
        if (response.doc){
          this.$store.dispatch('loadUsers', response.doc)
          this.$store.dispatch('loading', false)
        }
      } catch (error) {
        console.log(error.response.data.message)
      }  
    }
  }
}
</script>
