<template>
  <div>
    <div class="relative flex flex-col break-words w-full mb-6 shadow-lg rounded border-0 app-block">
      <div class="rounded-t bg-white mb-0 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold uppercase">Account Settings</h6>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-0 mt-4">
        <form @submit.prevent="updateAccountSettings" enctype="multipart/form-data">
          <div class="flex flex-wrap mb-6">
            <div class="w-full lg:w-4/12 pr-4">
              <div class="relative w-full mb-3">
                <label class="uppercase text-blueGray-600 text-xs font-bold">
                  Full Name
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  v-model="name"/>
              </div>
            </div>
           
            <div class="w-full lg:w-4/12 pr-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  v-model="email" />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 pr-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  New Password
                </label>
                <input
                  autocomplete="new-password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  v-model="password" />
              </div>
            </div>
            <div class="w-full lg:w-4/12 pr-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  v-model="confirmNewPassword" />
              </div>
            </div>
          </div>

          <div class="w-full lg:w-12/12">
            <button 
              type="submit"
              class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="relative flex flex-col break-words w-full mb-6 shadow-lg rounded border-0 app-block">
      <div class="rounded-t bg-white mb-0 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold uppercase">General Settings</h6>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-0">
        <form @submit.prevent="saveGeneralSettings" enctype="multipart/form-data">
          <div class="flex flex-wrap mt-4">
            <div class="w-full lg:w-12/12">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  About
                </label>
                <textarea
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  rows="4"
                  v-model="about">
                </textarea>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password">
                  Terms & Conditions
                </label>
                <textarea
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  rows="4"
                  v-model="termsConditions">
                </textarea>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password">
                  Privacy Policy
                </label>
                <textarea
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150"
                  rows="4"
                  v-model="privacyPolicy">
                </textarea>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-12/12">
            <button 
              type="submit"
              class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
import { ToastSuccess, ToastError } from '../../utils/Swal'

export default {
  data () {
    return {
      name: this.$store.getters.getName,
      email: this.$store.getters.getEmail,
      password: '',
      confirmNewPassword: '',
      about: '',     
      termsConditions: '',
      privacyPolicy: ''
    }
  },

  methods: {

    async updateAccountSettings() {

      if (this.password !== this.confirmNewPassword) {
        return ToastError.fire({
          title: `<p style="color: #fff;">Passwords does not match!</p>`
        })
      }

      try {
        const { data:  response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/update-account`, {
          id: this.$store.getters.getUserId,
          name: this.name,
          email: this.email,
          password: this.password
        },{ withCredentials: true })

        if (response.doc) {
          await this.$store.dispatch('setUser', response.doc)
          ToastSuccess.fire({
            title: `<p style="color: #fff;">${response.message}</p>`
          })
        }
      } catch (error) {
        ToastError.fire({
          title: `<p style="color: #fff;">Account setting not updated!</p>`
        })
        console.log(error.response)
      }
    },

    async saveGeneralSettings() {
      try {
        const { data:  response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/save-general-settings`, {
          id: this.$store.state.user_id,
          termsConditions: this.termsConditions,
          privacyPolicy: this.privacyPolicy,
          about: this.about
        },{ withCredentials: true })

        if (response.doc) {
          await this.$store.dispatch('setGeneralSettings', response.doc[0])
          ToastSuccess.fire({
            title: `<p style="color: #fff;">${response.message}</p>`
          })
        }
      } catch (error) {
        ToastError.fire({
          title: `<p style="color: #fff;"> General setting not updated!</p>`
        })
        console.log(error.response)
      }
    }
  },

  async mounted() {
    try {
      const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/get-general-settings`, {},
      { withCredentials: true })

      if (response.doc) this.$store.dispatch('setGeneralSettings', response.doc)

      this.about = response.doc.about
      this.termsConditions = response.doc.termsConditions
      this.privacyPolicy = response.doc.privacyPolicy

    } catch (error) {
      console.log(error.response.data)
    }
  }
}
</script>
