<template>
  <!-- Navbar -->
  <nav class="bg-white w-full z-10 md:flex-row md:flex-nowrap px-6 flex items-center justify-between pr-6 py-2 ">
    <!-- User -->
     <div class="flex items-center">
       <router-link to="/admin/dashboard" v-slot="{ href, navigate, isActive }">
        <a
          :href="href"
          @click="navigate"
          class="text-base py-3 font-semibold block"
          :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
          <div class="logo mr-32">LOGO</div>
          
        </a>
      </router-link>
      <ul class="md:flex-row list-none items-center hidden md:flex">
        <li class="items-center mr-4">
          <router-link to="/admin/calendar" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Kalènder
            </a>
          </router-link>
        </li>    
        <li class="items-center mr-4">
          <router-link to="/admin/categories" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Kategoria
            </a>
          </router-link>
        </li>
        <li class="items-center mr-4">
          <router-link to="/admin/prayers" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Orashon
            </a>
          </router-link>
        </li>
        <li class="items-center mr-4">
          <router-link to="/admin/katisashi" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Katisashi
            </a>
          </router-link>
        </li>
        <li class="items-center mr-4">
          <router-link to="/admin/music" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Músika
            </a>
          </router-link>
        </li>
        <li class="items-center mr-4">
          <router-link to="/admin/events" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Evento
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="flex justify-between">
      <ul class="flex-col md:flex-row list-none items-center hidden md:flex md:justify-end">
       
        <li class="items-center mr-4">
          <router-link to="/admin/settings" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              class="py-3 font-semibold block"
              :class="[ isActive ? 'text-blue-500 hover:text-blue-600' : 'text-blueGray-700 hover:text-blueGray-500']">
              Settings
            </a>
          </router-link>
        </li>
        <li class="items-center">
          <!-- <router-link to="/" v-slot="{ href, navigate, isActive }"> -->
          <a
            @click="signout"
            class="py-3 font-semibold block hover:text-blue-600 cursor-pointer">
            <i
              class="fas fa-sign-out-alt mr-1 text-sm">
            </i>
            Sign Out
          </a>
          <!-- </router-link> -->
        </li>
        <i class="fas fa-sign-out"></i>
      </ul>
    </div>
    
  </nav>
  <!-- End Navbar -->
</template>

<script>
const axios = require('axios').default

export default {
  methods: {
    async signout() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/logout`, {}, { withCredentials: true })
        if (response.logout) {
          window.localStorage.clear()
          this.$store.dispatch('setAuth', false)
          this.$router.replace({ path: '/login' })
        }
       //TODO: alert user
      } catch (error) {
        console.log(error.response.data.message)
      }
    }
  }
}
</script>
