import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { app } from '../plugins/firebase'
import { ToastError } from '../utils/Swal';
const storage = getStorage(app)
export default class FirebaseStorage {

  async save(object) {
    const file = object.file
    if (file !== null) {
      return new Promise( (resolve, reject) => {  
        const imageRef = ref(storage, `${object.path}${file.name}`)
        const uploadTask = uploadBytesResumable(imageRef, file)
        
        uploadTask.on('action_changed', () => {
          // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
        }, error => {
          console.log(error)
          reject(error)
        }, async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            file.downloadURL = downloadURL
            resolve({ file })
          } catch (error) {
            ToastError.fire({
              title: `<p style="color: #fff";>Error: image wasn't saved successfully!. Try again or contact support for assistance.</p>`
            })
            console.log('Error in catch FirebaseStorage/save => ', error)

            // TODO: Check "deleteResult.deleted" logic
            const deleteResult = await this.delete(object)
            if (deleteResult.deleted) reject(error)
          }
        })
      }) 
    }
  }

  async edit(object) {
    try {
      // this statement runs when new image is uploaded
      if (object.file && object.previousFilename) {
        if (!this.delete(object)) {
          throw new Error('Something when wrong. try again or contact support for assistance. Code: strge_err')
        }
        
        return this.save(object)
      }
    } catch (error) {
      ToastError.fire({
        title: `<p style="color: #fff";>Error: image wasn't saved successfully!. Try again or contact support for assistance.</p>`
      })
      console.log('Error in catch FirebaseStorage/edit => ', error)
      return error
    }
  }

  async delete(object) {
    let previousImageRef = ref(storage, `${object.path}${object.previousFilename}`)
  
    try {
      await deleteObject(previousImageRef);
      return true;
    } catch (error) {
      ToastError.fire({
        title: `<p style="color: #fff";>Error: image wasn't deleted successfully!. Try again or contact support for assistance.</p>`
      });
      console.log('Error in catch FirebaseStorage/delete => ', error);
      return false;
    }
  }

}