<template>
  <div>
    <CategoriesList />
  </div>
</template>

<script>
import CategoriesList from '@/components/CategoriesList'
  export default {
    components: {
      CategoriesList
    }
  }
</script>

<style scoped>

</style>