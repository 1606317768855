<template>
  <div>
    <div class="app-block flex flex-col shadow-lg">
      <h2 class="mb-4 text-blueGray-700 text-lg font-semibold uppercase">
        Date
      </h2>

      <div>
        <label class="mr-6 text-blueGray-500 text-base">Select date</label>
        <flat-pickr
          v-model="selectedDate"
          @onChange="selectDate"
          class="mb-4 border-0 p-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow ease-linear transition-all duration-150" />
      </div>
    </div>

    <div v-if="selectedDate">
      <div class="app-block mb-22 flex flex-col shadow-lg">
        <h2
          class="mb-4 text-blueGray-700 text-lg font-semibold uppercase"
          for="liturgia">
          Lauden orashon di mainta
        </h2>

        <div class="flex flex-col mb-2">
          <label class="mb-2 text-blueGray-500 text-base">
            Title
          </label>

          <input
            v-model="form.liturgiaDayTitle"
            class="mb-4 w-1/2 border-0 p-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm ease-linear transition-all duration-150"
            type="text" />
        </div>

        <label class="mb-2 w-1/4 text-blueGray-500 text-base">
          Text
        </label>

        <Editor v-model="form.liturgiaDayText" class="mb-4" />

        <div class="W-1/2">
          <div class="flex items-center mb-2">
            <label class="mr-2 text-blueGray-500 text-base">
              Youtube
            </label>

            <button 
              @click="addYoutubeUrlInputField('day')">
              <i class="fa fa-plus-square" aria-hidden="true"></i>
            </button>
          </div>

          <div id="yt-url-wrapper-day">
            <input
              v-for="(url, index) in youtubeLiturgiaDayUrls"
              :key="index"
              :value="url"
              class="yt-url w-3/5 border-0 p-3 mb-2 mr-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm ease-linear transition-all duration-150"
              type="url">
          </div>
        </div>
      </div>

      <div class="app-block mb-22 flex flex-col shadow-lg">
        <h2
          class="mb-4 text-blueGray-700 text-lg font-semibold uppercase"
          for="liturgia">
          Vespers orachon di anochi
        </h2>

        <label class="mb-2 text-blueGray-500 text-base">
          Title
        </label>

        <input
          v-model="form.liturgiaNightTitle"
          class="mb-4 w-1/2 border-0 p-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm ease-linear transition-all duration-150"
          type="text" />

        <label class="mb-2 text-blueGray-500 text-base">
          Text
        </label>

        <Editor v-model="form.liturgiaNightText" class="mb-4" />

        <div class="flex items-center mb-2">
          <label class="mr-2 text-blueGray-500 text-base">
            Youtube
          </label>

          <button 
            @click="addYoutubeUrlInputField('night')">
            <i class="fa fa-plus-square" aria-hidden="true"></i>
          </button>
        </div>

        <div id="yt-url-wrapper-night">
          <div v-for="(url, index) in youtubeLiturgiaNightUrls"
          :key="index">
            <input
              :value="url"
              class="yt-url w-3/5 border-0 p-3 mb-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm ease-linear transition-all duration-150"
              type="url">
          </div>
        </div>
      </div>

      <div class="app-block flex flex-col shadow-lg">
        <h2
          class="mb-4 text-blueGray-700 text-lg font-semibold uppercase"
          for="liturgia">
          Message of the day
        </h2>
        
        <Editor v-model="form.msgOfTheDay" />
      </div>
      
      <button 
        @click="save"
        class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
        {{ queryMode === 'edit' ? 'Update' : 'Save' }}
      </button>
    </div>
  </div>
</template>

<script>
import Editor from './Editor.vue'
import axios from 'axios'
import { ToastSuccess, ToastError } from '../utils/Swal'
import { getDay, parseISO } from 'date-fns'

export default {

  components: {
    Editor
  },

  data() {
    return {
      selectedDate: '',
      form: {
        liturgiaDayTitle: '',
        liturgiaDayText: '',
        liturgiaNightTitle: '',
        liturgiaNightText: '',
        msgOfTheDay: '',
      },

      editor: null,
      queryMode: null,

      youtubeLiturgiaDayUrls: [],
      youtubeLiturgiaNightUrls: []
    }
  },

  methods: {

    addYoutubeUrlInputField(type) {
      const inputField = document.createElement('input')
      inputField.type = 'url'
      inputField.dataset.liturgia = type
      inputField.classList.add('yt-url', 'w-3/5', 'border-0', 'p-3', 'mt-1', 'placeholder-blueGray-300', 'text-blueGray-600', 'bg-white', 'rounded', 'text-sm', 'ease-linear', 'transition-all', 'duration-150')
      document.getElementById('yt-url-wrapper-' + type).appendChild(inputField)
    },

    async selectDate(selectedDate, dateStr, instance) { /*eslint-disable-line */
      this.resetForm()
      this.queryMode = null
      this.selectedDate = dateStr
      await this.getPost()
      this.setMode()
    },

    async setMode() {
      if (this.isDataSet(this.form)) {
        this.queryMode = 'edit'
        this.$router.push({ path: '/admin/calendar', query: { date: this.selectedDate, mode: this.queryMode } })
      } else {
        this.queryMode = 'new'
        this.$router.push({ path: '/admin/calendar', query: { date: this.selectedDate, mode: this.queryMode } })
      }
    }, 

    isDataSet(fields) {
      let isDataSet = false
      for (const field in fields) {
        if (fields[field] !== '') {
          isDataSet = true 
        }
      }
      return isDataSet
    },
    
    async save() {

      if (!this.selectedDate) {
        return ToastError.fire({
          title: `<p style="color: #fff";>Error: no date selected</p>`
        })
      }

      if (!this.isDataSet(this.form)) {
        return ToastError.fire({
          title: `<p style="color: #fff";>Form cannot be empty!</p>`
        })
      }

      this.setYoutubeUrls()

      const formData = {
        date: this.selectedDate,
        ...this.form,
        youtubeLiturgiaDayUrls: this.youtubeLiturgiaDayUrls,
        youtubeLiturgiaNightUrls: this.youtubeLiturgiaNightUrls,
        weekDay: this.getWeekDay(parseISO(this.selectedDate)),
      }
    
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/${this.queryMode}-post`, 
          formData, {
          withCredentials: true
        })
      
        if (response.saved) {
          this.resetForm()
          ToastSuccess.fire({
            title: `<p style="color: #fff";>${response.message}</p>`
          })
        }
      } catch (error) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      }
    },

    setYoutubeUrls() {
      
      this.youtubeLiturgiaDayUrls = []
      document.getElementById('yt-url-wrapper-day').children.forEach(child => {
        if (child.value) {
          this.youtubeLiturgiaDayUrls.push(child.value)
        }
      })

      this.youtubeLiturgiaNightUrls = []
      document.getElementById('yt-url-wrapper-night').children.forEach(child => {
        if (child.value) {
          this.youtubeLiturgiaNightUrls.push(child.value)
        }
      })
    },
    
    addYoutubeUrlInputFieldsOnLoad() {

      if (document.getElementById('yt-url-wrapper-day').children.length === 0) {
        this.addYoutubeUrlInputField('day')
      }

      if (document.getElementById('yt-url-wrapper-night').children.length === 0) {
        this.addYoutubeUrlInputField('night')
      } 
    },

    getWeekDay(date){
      const weekDayNum = getDay(date)
      let weekDay 
      switch (weekDayNum) {
        case 0:
          weekDay = 'Djadumingu'
          break;
        case 1:
          weekDay = 'Djaluna'
          break;
        case 2:
          weekDay = 'Djamars'
          break;
        case 3:
          weekDay = 'Djarason'
          break;
        case 4:
          weekDay = 'Djawebs'
          break;
          case 5:
          weekDay = 'Djabierne'
          break;
          case 6:
          weekDay = 'Djasabra'
          break;
      }
      return weekDay
    },

    resetForm() {
      this.selectedDate = ''
      this.form = {
        liturgiaDayTitle: '',
        selectedDate: '',
        liturgiaDayText: '',
        liturgiaNightTitle: '',
        liturgiaNightText: '',
        msgOfTheDay: '',
      }
      this.youtubeLiturgiaDayUrls = [],
      this.youtubeLiturgiaNightUrls = []
    },

    async getPost() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/query-post`, {
          date: this.selectedDate
        }, { withCredentials: true })

        if (response.doc) {
          for (const prop in response.doc) {
            if (prop === 'date') {
              this.selectedDate = response.doc[prop]
            } else {
              this.form[prop] = response.doc[prop]
            }

            if (prop === 'youtubeLiturgiaDayUrls') {
              this.youtubeLiturgiaDayUrls = response.doc[prop]
            }

            if (prop === 'youtubeLiturgiaNightUrls') {
              this.youtubeLiturgiaNightUrls = response.doc[prop]
            }
          }

          this.addYoutubeUrlInputFieldsOnLoad()
        } else {
          this.addYoutubeUrlInputFieldsOnLoad()
        }
      } catch (error) {
        error.response ? console.log(error.response.data.message) : console.log(error)
      }
    }
  }
}
</script>