<template>
  <div 
    @click="$emit('close-modal')"
    class="modal-overlay">
    <div
      @click.stop 
      class="modal p-4 overflow-y-scroll">
      <h2
        class="mb-4 text-blueGray-700 text-lg font-semibold uppercase">
        New Event
      </h2>

      <div class="flex flex-col mb-2">
        <label class="mb-2 text-blueGray-500 text-base">Date</label>
        <flat-pickr
          v-model="form.date"
          class="mb-4 w-1/2 border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow ease-linear transition-all duration-150" />
        
        <label class="mb-2 text-blueGray-500 text-base">
          Title
        </label>

        <input
          v-model="form.title"
          class="mb-4 w-1/2 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm shadow ease-linear transition-all duration-150"
          type="text"
          />
      </div>

      <label class="mb-2 w-1/4 text-blueGray-500 text-base">
        Text
      </label>

      <Editor v-model="form.text" class="mb-4"/>

      <div class="flex flex-col mb-4">
        <label
          class="text-blueGray-500 text-base mb-2"
          for="file">Image
        </label>
        <div class="flex items-center">
          <img
            v-if="form.downloadURL"
            :src="form.downloadURL"
            alt=""
            style="width: 80px; height: 100px;"
            class="mr-4">
        
          <input
            type="file"
            name="file" 
            id="file"
            ref="file"
            @change="handleFileUpload()">
        </div>
      </div>

      <div class="flex flex-col mb-2">
        <label class="mb-2 text-blueGray-500 text-base">
          Youtube URL
        </label>

        <input
          v-model="form.youtubeURL"
          class="mb-4 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm shadow ease-linear transition-all duration-150"
          type="url"
          />
      </div>
      
      <div>
        <button
          @click="save"
          class="bg-blue-500 hover:bg-blue-700 text-white font-semibold mt-4 py-2 px-4 rounded mr-2">
          {{ queryMode === 'edit' ? 'Update' : 'Save' }}
        </button>
        <button
          @click="hideModal"
          class="bg-gray-500 hover:bg-gray-700 text-white font-semibold mt-4 py-2 px-4 rounded">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '../Editor.vue'
import { ToastError } from '../../utils/Swal'

export default {

  props: {
    eventObject: {
      type: Object
    }
  },

  components: {
    Editor
  },

  data() {
    return {
      form: {
        id: '',
        date: '',
        title: '',
        text: '',
        youtubeURL: '',
        filename: '',
        filePath: '',
        downloadURL: ''
      },
      editor: null,
      queryMode: 'new',

      file: null,
      imageUploadError: false,
      path: '/events'
    }
  },

  methods: {
    checkFromField(form) {
      let isFormEmpty = false
      for (const field in form) {
        if (form[field] !== '' && form[field] !== 'youtubeURL') isFormEmpty = true
      }
      return isFormEmpty
    },

    hideModal() {
      this.$emit('hideModal')
    },

    async save() {
      if (this.imageUploadError) return this.handleFileUpload()

      if (!this.checkFromField(this.form)) {
        return ToastError.fire({
          title: `<p style="color: #fff";>Form fields cannot be empty!</p>`
        })
      } else {
        this.$emit('save', {
          file: this.file,
          form: this.form,
          path: this.path,
          queryMode: this.queryMode
        })

        this.resetForm()
        this.$emit('close-modal')
      }
    },

    resetForm() {
      this.form = {
        date: '',
        id: '',
        title: '',
        text: '',
        youtubeURL: '',
        filename: '',
        filePath: '',
        downloadURL: ''
      }
    },

    handleFileUpload() {
      // Reset prop
      this.imageUploadError = false
      
      if (this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0]
        if (file.size > 3048576) {
          this.imageUploadError = true
          // TODO: alert user
          alert(`Image of max 3 MegaByte or less are allowed.`)
        }
      
        if (!this.imageUploadError) {
          this.file = this.$refs.file.files[0]
        }
      }
    }
  },

   mounted() {
    if (Object.keys(this.eventObject).length > 0) {
      this.queryMode = 'edit'
    }
    this.form.id = this.eventObject._id
    this.form.date = this.eventObject.date
    this.form.title = this.eventObject.title
    this.form.text = this.eventObject.text
    this.form.youtubeURL = this.eventObject.youtubeURL
    this.form.filename = this.eventObject.filename
    this.form.filePath = this.eventObject.filePath
    this.form.downloadURL = this.eventObject.downloadURL
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #313131da;
}

.modal {
  background-color: white;
  min-height: 500px;
  width: 700px;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
}
</style>