<template>
  <div>
    <DeleteModal
      @close-delete-modal="showDeleteModal = !showDeleteModal"
      @deleteItem="deleteKatisashi"
      @cancelDeleteProcess="cancelDeleteProcess"
      v-if="showDeleteModal"
      itemType="Katisashi"
      />
    <KatisashiModal
      @close-modal="showModal = !showModal"
      @save="save"
      @hideModal="showModal = !showModal"
      v-if="showModal"
      :katisashiObject="katisashiObject"
    />
    <div class="flex justify-between items-center py-4">
      <h2
        class="text-blueGray-700 text-lg font-semibold">
        Katisashi list
      </h2>
      <button
        @click="openModal"
        class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">Add Katisashi</button>
    </div>
    <div class="app-block mb-22 shadow-lg">
      <div class="">
          <ul>
            <li 
              v-for="(katisashi, idx) in katisashiList"
              :key="idx"
              class="flex justify-between pt-4 pb-4">
              <div class="">
                <h2
                  v-html="katisashi.title"
                  class="text-lg font-semibold">
                </h2>
                <p
                  v-if="katisashi.categoryId"
                  class="font-semibold text-xs">Category:
                  <span class="text-xs font-normal">
                    {{ categoryName(katisashi.categoryId) }}
                  </span>
                </p>
              </div>
              <div class="flex justify-between">
                <span>
                  <i
                    @click="edit(katisashi._id)"
                    class="fas fa-pencil-alt cursor-pointer"></i>
                </span>
                <span>
                  <i
                    @click="showDeleteKatisashiModal(katisashi._id)"
                    class="fas fa-trash-alt ml-4 cursor-pointer"></i>
                </span>
              </div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import KatisashiModal from '@/components/modals/KatisashiModal';
import DeleteModal from '@/components/modals/DeleteModal';
import { ToastSuccess, ToastError } from '../utils/Swal'
import FirebaseStorage from '../utils/fileUpload'

import axios from 'axios'

export default {
  components: {
    KatisashiModal,
    DeleteModal
  },

  data() {
    return {
      katisashiObject: {},
      katisashiList: [],
      showModal: false,
      showDeleteModal: false,
      idOfItemToDelete: null,

      katisashiObjectToSave: {},
      firebaseStorage: new FirebaseStorage()
    }
  },

  methods: {

    openModal() {
      this.katisashiObject = {}
      this.showModal = !this.showModal
    },

    categoryName(id) {
      const category = this.$store.getters.getCategories.find(category => category._id === id)
      if (category) {
        return category.name
      } else {
        return ''
      }
    },

    async save(object) {
      this.katisashiObjectToSave = object

      if (object.file !== null) {
        if (this.katisashiObjectToSave.queryMode === 'new') {
          await this.saveFileInStorage()
        } else if (this.katisashiObjectToSave.queryMode === 'edit') {
          await this.editFileInStorage() 
        }
        this.databaseHandler()
      } else {
        this.databaseHandler()
      }
    },

    async databaseHandler() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/${this.katisashiObjectToSave.queryMode}-katisashi`, 
          this.katisashiObjectToSave.form,
          { withCredentials: true })
      
        if (response.saved) {
          ToastSuccess.fire({
            title: `<p style="color: #fff";>${response.message}</p>`
          })

          if (this.katisashiObjectToSave.queryMode === 'new') {
            this.$store.dispatch('setNewKatisashi', response.doc)
          } else if (this.katisashiObjectToSave.queryMode === 'edit') {
            this.$store.dispatch('editKatisashi', response.doc)
            this.refreshList()
          }
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    async saveFileInStorage() {
      let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
      this.katisashiObjectToSave.form.filePath = `${this.katisashiObjectToSave.path}/${uniqueId}/`

      const payload = await this.firebaseStorage.save({
        file: this.katisashiObjectToSave.file,
        path: this.katisashiObjectToSave.form.filePath
      })

      this.katisashiObjectToSave.form.filename = payload.file.name
      this.katisashiObjectToSave.form.downloadURL = payload.file.downloadURL
    },

    async editFileInStorage() {
      const payload = await this.firebaseStorage.edit({
        file: this.katisashiObjectToSave.file,
        previousFilename: this.katisashiObjectToSave.form.filename,
        path: this.katisashiObjectToSave.form.filePath
      })
          
      this.katisashiObjectToSave.form.filename = payload.file.name
      this.katisashiObjectToSave.form.downloadURL = payload.file.downloadURL
    },
    
    edit(id) {
      this.katisashiObject = this.katisashiList.find(katisashi => katisashi._id === id)
      this.showModal = !this.showModal
    },

    cancelDeleteProcess() {
      this.idOfItemToDelete = null
      this.showDeleteModal = false
    },

    async deleteKatisashi() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/delete-katisashi`,
        { id: this.idOfItemToDelete },
        { withCredentials: true })

        if (response.deleted) {
          this.firebaseStorage.delete({
            path: response.doc.filePath,
            previousFilename: response.doc.filename
          })

          this.$store.dispatch('deleteKatisashi', this.idOfItemToDelete)
          this.idOfItemToDelete = null
          this.refreshList()
          this.showDeleteModal = false
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    showDeleteKatisashiModal(id) {
      this.idOfItemToDelete = id
      this.showDeleteModal = true
    },
    
    refreshList() {
      this.katisashiList = this.$store.state.katisashi
    }
  },

  async mounted() {
    try {
      const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/get-katisashis`, {},
      { withCredentials: true })
      if (response.doc) {
        this.$store.dispatch('loadKatisashis', response.doc)
        this.katisashiList = (response.doc).reverse()
      }
    } catch (error) {
      if (error.response) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      } else {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

  ul li {
    border-top: 1px solid grey;
  }

  ul li:first-child {
    border-top: 0px solid grey;
  }
</style>