<template>
  <div 
    @click="$emit('close-delete-modal')"
    class="modal-overlay">
    <div
      @click.stop 
      class="modal p-4">
      <h2
        class="mb-4 text-blueGray-700 text-lg font-semibold uppercase">
        Delete {{ itemType }}
      </h2>

      <p class="mb-2 text-blueGray-500 text-base">
        Are you sure you want to delete this {{ (itemType).toLocaleLowerCase() }}?
      </p>

      <div>
        <button
          @click="deleteItem"
          class="bg-red-500 hover:bg-red-700 text-white font-semibold mt-4 py-2 px-4 rounded mr-2">
          Yes
        </button>

        <button
          @click="cancel"
          class="bg-gray-500 hover:bg-gray-700 text-white font-semibold mt-4 py-2 px-4 rounded">
          Cancel
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {

  props: {
    itemType: {
      type: String
    }
  },

  data() {
    return {}
  },

  methods: {
    deleteItem() {
      this.$emit('deleteItem')
    },

    cancel() {
      this.$emit('cancelDeleteProcess')
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #313131da;
}

.modal {
  background-color: white;
  height: fit-content;
  min-width: 400px;
  margin-top: 5%;
  border-radius: 5px;
}
</style>