import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    authenticated: false,
    user: {
      _id: '',
      role: '',
      name: '',
      email: '',
    },
    generalSettings: {
      about: '',
      termsConditions: '',
      privacyPolicy: ''
    },
    categories: [],
    prayers: [],
    music: [],
    katisashi: [],
    events: [],
    // loading: true
  },

  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
      paths: [
        'authenticated',
        'user.role',
        'user._id',
        'user.email',
        'user.name',
      ]
    })
  ],

  mutations: {
    SET_AUTH: (state, payload) => state.authenticated = payload,
    SET_USER: (state, payload) => state.user = payload,
    SET_GENERAL_SETTINGS: (state, payload) => state.generalSettings = payload,

    LOAD_CATEGORIES: (state, payload) => state.categories = payload, 
    SET_NEW_CATEGORY: (state, payload) => state.categories.unshift(payload),
    EDIT_CATEGORY (state, payload) {
      const categoryObjectIndex = state.categories.findIndex(category => category._id === payload._id)
      state.categories[categoryObjectIndex] = payload
    },
    DELETE_CATEGORY (state, payload) {
      const categoryObjectIndex = state.categories.findIndex(category => category._id === payload)
      state.categories.splice(categoryObjectIndex, 1)
    },

    LOAD_PRAYERS: (state, payload) => state.prayers = payload, 
    SET_NEW_PRAYER: (state, payload) => state.prayers.unshift(payload),
    EDIT_PRAYER (state, payload) {
      const prayerObjectIndex = state.prayers.findIndex(prayer => prayer._id === payload._id)
      state.prayers[prayerObjectIndex] = payload
    },
    DELETE_PRAYER (state, payload) {
      const prayerObjectIndex = state.prayers.findIndex(prayer => prayer._id === payload)
      state.prayers.splice(prayerObjectIndex, 1)
    },

    LOAD_EVENTS: (state, payload) => state.events = payload, 
    SET_NEW_EVENT: (state, payload) => state.events.unshift(payload),
    EDIT_EVENT (state, payload) {
      const eventObjectIndex = state.events.findIndex(event => event._id === payload._id)
      state.events[eventObjectIndex] = payload
    },
    DELETE_EVENT (state, payload) {
      const eventObjectIndex = state.events.findIndex(event => event._id === payload)
      state.events.splice(eventObjectIndex, 1)
    },

    LOAD_MUSIC: (state, payload) => state.music = payload, 
    SET_NEW_MUSIC: (state, payload) => state.music.unshift(payload),
    EDIT_MUSIC (state, payload) {
      const musicObjectIndex = state.music.findIndex(music => music._id === payload._id)
      state.music[musicObjectIndex] = payload
    },
    DELETE_MUSIC (state, payload) {
      const musicObjectIndex = state.music.findIndex(music => music._id === payload)
      state.music.splice(musicObjectIndex, 1)
    },

    LOAD_KATISASHI: (state, payload) => state.katisashi = payload, 
    SET_NEW_KATISASHI: (state, payload) => state.katisashi.unshift(payload),
    EDIT_KATISASHI (state, payload) {
      const katisashiObjectIndex = state.katisashi.findIndex(katisashi => katisashi._id === payload._id)
      state.katisashi[katisashiObjectIndex] = payload
    },
    DELETE_KATISASHI (state, payload) {
      const katisashiObjectIndex = state.katisashi.findIndex(katisashi => katisashi._id === payload)
      state.katisashi.splice(katisashiObjectIndex, 1)
    },

    LOADING: (state, payload) => state.loading = payload
  },

  actions: {
    setAuth: ({ commit }, payload) => commit('SET_AUTH', payload),
    setUser: ({ commit }, payload) => commit('SET_USER', payload),
    setGeneralSettings: ({ commit }, payload) => commit('SET_GENERAL_SETTINGS', payload),
    
    loadCategories: ({ commit }, payload) => commit('LOAD_CATEGORIES', payload),
    setNewCategory: ({ commit }, payload) => commit('SET_NEW_CATEGORY', payload),
    editCategory: ({ commit }, payload) => commit('EDIT_CATEGORY', payload),
    deleteCategory: ({ commit }, payload) => commit('DELETE_CATEGORY', payload),

    loadPrayers: ({ commit }, payload) => commit('LOAD_PRAYERS', payload),
    setNewPrayers: ({ commit }, payload) => commit('SET_NEW_PRAYER', payload),
    editPrayer: ({ commit }, payload) => commit('EDIT_PRAYER', payload),
    deletePrayer: ({ commit }, payload) => commit('DELETE_PRAYER', payload),

    loadEvents: ({ commit }, payload) => commit('LOAD_EVENTS', payload),
    setNewEvent: ({ commit }, payload) => commit('SET_NEW_EVENT', payload),
    editEvent: ({ commit }, payload) => commit('EDIT_EVENT', payload),
    deleteEvent: ({ commit }, payload) => commit('DELETE_EVENT', payload),

    loadMusic: ({ commit }, payload) => commit('LOAD_MUSIC', payload),
    setNewMusic: ({ commit }, payload) => commit('SET_NEW_MUSIC', payload),
    editMusic: ({ commit }, payload) => commit('EDIT_MUSIC', payload),
    deleteMusic: ({ commit }, payload) => commit('DELETE_MUSIC', payload),

    loadKatisashis: ({ commit }, payload) => commit('LOAD_KATISASHI', payload),
    setNewKatisashi: ({ commit }, payload) => commit('SET_NEW_KATISASHI', payload),
    editKatisashi: ({ commit }, payload) => commit('EDIT_KATISASHI', payload),
    deleteKatisashi: ({ commit }, payload) => commit('DELETE_KATISASHI', payload),

    loading: ({ commit }, payload) => commit('LOADING', payload),
  },
  
  getters: {
    isAuth: (state) => state.authenticated,
    loading: (state) => state.loading,
    getEmail: (state) => state.user.email,
    getName: (state) => state.user.name,
    getUserId: (state) => state.user._id,
    getGeneralSettings: (state) => state.generalSettings,
    getCategories: (state) => state.categories,
    getPrayers: (state) => state.prayers,
    getMusic: (state) => state.music,
    getEvents: (state) => state.events,
    getKatisashis: (state) => state.katisashi
  }
})