<template>
  <div>
    <DeleteModal
      @close-delete-modal="showDeleteModal = !showDeleteModal"
      @deleteItem="deleteCategory"
      @cancelDeleteProcess="cancelDeleteProcess"
      v-if="showDeleteModal"
      itemType="Category"
      />
    <CategoryModal
      v-if="showModal"
      @hideModal="showModal = !showModal"
      @close-modal="showModal = !showModal"
      @save="save"
      :categoryObject="categoryObject"
       />
    <div class="flex justify-between items-center py-4">
      <h2
        class="text-blueGray-700 text-lg font-semibold">
        Kategoria
      </h2>
      <button
        @click="openModal"
        class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">Add Kategoria</button>
    </div>
    <div class="app-block mb-22 shadow-lg">
      <div class="">
          <ul>
            <li 
              v-for="(category, idx) in categoriesList"
              :key="idx"
              class="flex justify-between pt-4 pb-4">
              <div>
                <h2
                  v-html="category.name"
                  class="text-lg font-semibold">
                </h2>
                <p
                  v-if="category.type" 
                  class="font-normal text-xs"> {{ (category.type).slice(0,1).toUpperCase() + category.type.slice(1) }} category
                </p>
              </div>
              <div class="flex justify-between">
                <span>
                  <i
                    @click="edit(category._id)"
                    class="fas fa-pencil-alt cursor-pointer"></i>
                </span>
                <span>
                  <i
                    @click="showDeleteCategoryModal(category._id)"
                    class="fas fa-trash-alt ml-4 cursor-pointer"></i>
                </span>
              </div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryModal from '@/components/modals/CategoryModal';
import DeleteModal from '@/components/modals/DeleteModal';
import { ToastSuccess, ToastError } from '../utils/Swal'
import axios from 'axios'

export default {
  components: {
    CategoryModal,
    DeleteModal
  },

  data() {
    return {
      categoryObject: {},
      categoriesList: [],
      showModal: false,
      showDeleteModal: false,
      idOfItemToDelete: null,
      categoryObjectToSave: {}
    }
  },

  methods: {

    openModal() {
      this.categoryObject = {}
      this.showModal = !this.showModal
    },

    async save(object) {
      this.categoryObjectToSave = object
      this.databaseHandler()
    },

    async databaseHandler() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/${this.categoryObjectToSave.queryMode}-category`, 
          this.categoryObjectToSave.form,
          { withCredentials: true })
      
        if (response.saved) {
          ToastSuccess.fire({
            title: `<p style="color: #fff";>${response.message}</p>`
          })

          if (this.categoryObjectToSave.queryMode === 'new') {
            this.$store.dispatch('setNewCategory', response.doc)
          } else if (this.categoryObjectToSave.queryMode === 'edit') {
            this.$store.dispatch('editCategory', response.doc)
            this.refreshList()
          }
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    edit(id) {
      this.categoryObject = this.categoriesList.find(category => category._id === id)
      this.showModal = !this.showModal
    },

    cancelDeleteProcess() {
      this.idOfItemToDelete = null
      this.showDeleteModal = false
    },

    async deleteCategory() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/delete-category`, { id: this.idOfItemToDelete },
        { withCredentials: true })

        if (response.deleted) {
          this.$store.dispatch('deleteCategory', this.idOfItemToDelete)
          this.idOfItemToDelete = null
          this.refreshList()
          this.showDeleteModal = false
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    showDeleteCategoryModal(id) {
      this.idOfItemToDelete = id
      this.showDeleteModal = !this.showDeleteModal
    },
    
    refreshList() {
      this.categoriesList = this.$store.getters.getCategories
    }
  },

  async mounted() {
    try {
      const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/get-categories`, {},
      { withCredentials: true })
      if (response.doc) {
        this.$store.dispatch('loadCategories', response.doc)
        this.categoriesList = response.doc
      }
    } catch (error) {
      if (error.response) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      } else {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

  ul li {
    border-top: 1px solid grey;
  }

  ul li:first-child {
    border-top: 0px solid grey;
  }
</style>