<template>
  <CalendarForm />
</template>

<script>
import CalendarForm from '@/components/CalendarForm'

export default {
  name: "Calendar",
  components: {
    CalendarForm
  }
}
</script>
