<template>
  <div>
    <div class="relative bg-blueGray-100 min-h-screen pb-20">
      <admin-navbar />

      <div class="w-3/4 mx-auto pt-8">
        <router-view />
      </div>

    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/AdminNavbar.vue";

import { ToastError } from '../utils/Swal'
import axios from 'axios'

export default {
  name: "admin-layout",
  components: { AdminNavbar },

  async mounted() {
    try {
      const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/get-categories`, {},
      { withCredentials: true })
      if (response.doc) {
        this.$store.dispatch('loadCategories', response.doc)
      }
    } catch (error) {
      if (error.response) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      } else {
        console.log(error);
      }
    }
  }
}
</script>
