<template>
  <div>
    <DeleteModal
      v-if="showDeleteModal"
      @close-delete-modal="showDeleteModal = !showDeleteModal"
      @deleteItem="deletePrayer"
      @cancelDeleteProcess="cancelDeleteProcess"
      itemType="Prayer"
    />
    <PrayerModal
      v-if="showModal"
      @hideModal="showModal = !showModal"
      @close-modal="showModal = !showModal"
      @save="save"
      :prayerObject="prayerObject"
    />
    <div class="flex justify-between items-center py-4">
      <h2
        class="text-blueGray-700 text-lg font-semibold">
        Orashon
      </h2>
      <button
        @click="openModal"
        class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">Add Orashon</button>
    </div>
    <div class="app-block mb-22 shadow-lg">
      <div class="">
          <ul>
            <li 
              v-for="(prayer, idx) in prayersList"
              :key="idx"
              class="flex justify-between pt-4 pb-4">
              <div class="">
                <h2
                  v-html="prayer.title"
                  class="text-lg font-semibold">
                </h2>
                <p
                  v-if="prayer.categoryId"
                  class="font-semibold text-xs">Category:
                  <span class="text-xs font-normal">
                    {{ categoryName(prayer.categoryId) }}
                  </span>
                </p>
              </div>
              <div class="flex justify-between">
                <span>
                  <i
                    @click="edit(prayer._id)"
                    class="fas fa-pencil-alt cursor-pointer"></i>
                </span>
                <span>
                  <i
                    @click="showDeletePrayerModal(prayer._id)"
                    class="fas fa-trash-alt ml-4 cursor-pointer"></i>
                </span>
              </div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PrayerModal from '@/components/modals/PrayerModal';
import DeleteModal from '@/components/modals/DeleteModal';
import { ToastSuccess, ToastError } from '../utils/Swal'
import FirebaseStorage from '../utils/fileUpload'
import axios from 'axios'

export default {
  components: {
    PrayerModal,
    DeleteModal
  },

  data() {
    return {
      prayerObject: {},
      prayersList: [],
      showModal: false,
      showDeleteModal: false,
      idOfItemToDelete: null,

      prayerObjectToSave: {},
      firebaseStorage: new FirebaseStorage()
    }
  },

  methods: {

    categoryName(id) {
      const category = this.$store.getters.getCategories.find(category => category._id === id)
      if (category) {
        return category.name
      } else {
        return ''
      }
    },

    openModal() {
      this.prayerObject = {}
      this.showModal = !this.showModal
    },

    async save(object) {
      this.prayerObjectToSave = object

      if (object.file !== null) {
        if (this.prayerObjectToSave.queryMode === 'new') {
          await this.saveFileInStorage()
        } else if (this.prayerObjectToSave.queryMode === 'edit') {
          await this.editFileInStorage() 
        }
        this.databaseHandler()
      } else {
        this.databaseHandler()
      }
    },

    async databaseHandler() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/${this.prayerObjectToSave.queryMode}-prayer`, 
          this.prayerObjectToSave.form,
          { withCredentials: true })
      
        if (response.saved) {
          ToastSuccess.fire({
            title: `<p style="color: #fff";>${response.message}</p>`
          })

          if (this.prayerObjectToSave.queryMode === 'new') {
            this.$store.dispatch('setNewPrayers', response.doc)
          } else if (this.prayerObjectToSave.queryMode === 'edit') {
            this.$store.dispatch('editPrayer', response.doc)
            this.refreshList()
          }
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    async saveFileInStorage() {
      let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
      this.prayerObjectToSave.form.filePath = `${this.prayerObjectToSave.path}/${uniqueId}/`

      const payload = await this.firebaseStorage.save({
        file: this.prayerObjectToSave.file,
        path: this.prayerObjectToSave.form.filePath
      })
      
      this.prayerObjectToSave.form.filename = payload.file.name
      this.prayerObjectToSave.form.downloadURL = payload.file.downloadURL
    },

    async editFileInStorage() {
      const payload = await this.firebaseStorage.edit({
        file: this.prayerObjectToSave.file,
        previousFilename: this.prayerObjectToSave.form.filename,
        path: this.prayerObjectToSave.form.filePath
      })
      
      this.prayerObjectToSave.form.filename = payload.file.name
      this.prayerObjectToSave.form.downloadURL = payload.file.downloadURL
    },

    edit(id) {
      this.prayerObject = this.prayersList.find(prayer => prayer._id === id)
      this.showModal = !this.showModal
    },

    cancelDeleteProcess() {
      this.idOfItemToDelete = null
      this.showDeleteModal = false
    },

    async deletePrayer() {
      try {
        const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/delete-prayer`,
        { id: this.idOfItemToDelete },
        { withCredentials: true })

        if (response.deleted) {
          this.firebaseStorage.delete({
            path: response.doc.filePath,
            previousFilename: response.doc.filename
          })

          this.$store.dispatch('deletePrayer', this.idOfItemToDelete)
          this.idOfItemToDelete = null
          this.refreshList()
          this.showDeleteModal = false
        }
      } catch (error) {
        if (error.response) {
          ToastError.fire({
            title: `<p style="color: #fff";>${error.response.data.message}</p>`
          })
        } else {
          console.log(error);
        }
      }
    },

    showDeletePrayerModal(id) {
      this.idOfItemToDelete = id
      this.showDeleteModal = true
    },
    
    refreshList() {
      this.prayersList = this.$store.state.prayers
    },

    
  },

  async mounted() {
    try {
      const { data: response } = await axios.post(`${process.env.VUE_APP_DOMAIN_NAME}/api/admin/get-prayers`, {},
      { withCredentials: true })
      if (response.doc) {
        this.$store.dispatch('loadPrayers', response.doc)
        this.prayersList = response.doc
      }
    } catch (error) {
      if (error.response) {
        ToastError.fire({
          title: `<p style="color: #fff";>${error.response.data.message}</p>`
        })
      } else {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

  ul li {
    border-top: 1px solid grey;
  }

  ul li:first-child {
    border-top: 0px solid grey;
  }
</style>