<template>
  <div 
    @click="$emit('close-modal')"
    class="modal-overlay">
    <div
      @click.stop 
      class="modal p-4">
      <h2
        class="mb-4 text-blueGray-700 text-lg font-semibold uppercase">
        {{ queryMode === 'edit' ? 'Edit Category' : 'New Category' }}
      </h2>

      <div class="flex flex-col mb-2">
        <label class="mb-2 text-blueGray-500 text-base">
          Category Name
        </label>

        <input
          v-model="form.name"
          class="mb-4 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm shadow ease-linear transition-all duration-150"
          type="text"
          />
      </div>

      <div class="flex flex-col mb-2">
        <label class="mb-2 text-blueGray-500 text-base">
          Type
        </label>

        <select v-model="form.type">
          <option value="prayer">Prayer</option>
          <option value="music">Music</option>
          <option value="katisashi">Katisashi</option>
        </select>
      </div>

      <div>
        <button
          @click="save"
          class="bg-blue-500 hover:bg-blue-700 text-white font-semibold mt-4 py-2 px-4 rounded mr-2">
          {{ queryMode === 'edit' ? 'Update' : 'Save' }}
        </button>
        <button
          @click="hideModal"
          class="bg-gray-500 hover:bg-gray-700 text-white font-semibold mt-4 py-2 px-4 rounded">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ToastError } from '../../utils/Swal'

export default {

  props: {
    categoryObject: {
      type: Object
    }
  },

  data() {
    return {
      form: {
        id: '',
        name: '',
        type: '',
      },
      categories: [],
      queryMode: 'new'
    }
  },

  methods: {
    hideModal() {
      this.$emit('hideModal')
    },

    async save() {
      if (!this.form.name) {
        return ToastError.fire({
          name: `<p style="color: #fff";>Name field cannot be empty!</p>`
        })
      } else {
        this.$emit('save', {
          form: this.form,
          queryMode: this.queryMode
        })
        this.resetForm()
        this.$emit('close-modal')
      }
    },

    resetForm() {
      this.form = {
        id: '',
        name: '',
        type: ''
      }
    }
  },

  async mounted() {
    if (Object.keys(this.categoryObject).length > 0) {
      this.queryMode = 'edit'
    }

    this.form.id = this.categoryObject._id
    this.form.name = this.categoryObject.name
    this.form.type = this.categoryObject.type
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #313131da;
}
.modal {
  background-color: white;
  height: 330px;
  min-width: 400px;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
}
</style>