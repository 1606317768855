<template>
  <div>
    <EventList />
  </div>
</template>

<script>
import EventList from '@/components/EventList'
  export default {
    components: {
      EventList
    }
  }
</script>

<style scoped>

</style>